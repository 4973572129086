@tailwind base;
@tailwind components;
@tailwind utilities;


[data-theme='light'] {
  --indigo-100:#e0e7ff;
  --indigo-200:#c7d2fe;
  --indigo-300:#a5b4fc;
  --indigo-400:#818cf8;
  --indigo-500:#6366f1;
  --indigo-600:#4f46e5;
  --indigo-700:#4338ca;
  --indigo-800:#3730a3;
  --indigo-900:#312e81;
  --background: #ffffff;
  --text: #1F2937;
}
[data-theme='dark'] {
  --indigo-100:#e0e7ff;
  --indigo-200:#c7d2fe;
  --indigo-300:#a5b4fc;
  --indigo-400:#818cf8;
  --indigo-500:#6366f1;
  --indigo-600:#4f46e5;
  --indigo-700:#4338ca;
  --indigo-800:#3730a3;
  --indigo-900:#312e81;
  --background: #000000
  ;
  --text: #f2f2f2;
}

